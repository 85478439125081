import React, {
  ReactElement,
  FunctionComponent,
  createContext,
  useState,
  useEffect,
} from 'react';
import getBrandingData from 'hooks/useInternalBranding';
import { BrandingData } from 'constants/home';
import {
  Branding,
  BrandingImages,
  BrandingColors,
  BrandingAffiliation,
  BrandingOptions,
  BrandingContent,
  BrandingPaths,
  BrandingCustomSignIn,
  BrandingDefaults,
} from '../../interfaces/branding';

interface BrandingContextType {
  images: BrandingImages;
  colors: BrandingColors;
  affiliation: BrandingAffiliation;
  options: BrandingOptions;
  paths: BrandingPaths;
  customSignIn: BrandingCustomSignIn;
  default: BrandingDefaults;
}

interface BrandingProps {
  branding?: BrandingContextType;
  brandingContent?: BrandingContent;
  internalBrandingData?: any;
  changeBrandingContent(BrandingContent): void;
}

export const BrandingContext = createContext<BrandingProps>({
  branding: undefined,
  brandingContent: undefined,
  changeBrandingContent: () => {},
});
export const BrandingConsumer = BrandingContext.Consumer;

interface Props {
  children: ReactElement;
  branding?: Branding;
}
export const BrandingProvider: FunctionComponent<Props> = ({
  children,
  branding,
}: Props) => {
  const [brandingContent, setBrandingContent] = useState<
    BrandingContent | undefined
  >(branding?.content);
  const [internalBrandingData, setInternalBrandingData] = useState<
    BrandingData | undefined
  >(undefined);

  const getInternalBranding = async (): Promise<void> => {
    const brandingData = await getBrandingData();
    setInternalBrandingData(brandingData);
  };

  useEffect(() => {
    getInternalBranding();
  }, []);

  const changeBrandingContent = (newContent: BrandingContent) =>
    setBrandingContent(newContent);

  return (
    <BrandingContext.Provider
      value={{
        branding,
        brandingContent,
        changeBrandingContent,
        internalBrandingData,
      }}
    >
      {children}
    </BrandingContext.Provider>
  );
};
export default BrandingProvider;
