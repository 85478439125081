import getSystemSettingsAsync from 'api/systemSettings/getSystemSettings';
import { BRANDING_DATA, BrandingData } from 'constants/home';

const patientEvernorthBrandingEnabled = (systemSettings): boolean =>
  systemSettings.find(
    (systemSetting) =>
      systemSetting.name === 'patient_evernorth_branding_enabled'
  ).value;

const brandingData = (isEnabled): BrandingData =>
  isEnabled === 'true' ? BRANDING_DATA.evernorth : BRANDING_DATA.mdlive;

const getBrandingData = async (): Promise<BrandingData> => {
  const { systemSettings } = await getSystemSettingsAsync();
  const internalBrandingEnabled = patientEvernorthBrandingEnabled(
    systemSettings
  );

  return brandingData(internalBrandingEnabled);
};

export default getBrandingData;
